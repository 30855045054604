export function calculateRowsAndColumns(totalAttd: number, attdPerRow: number) {
  const columns = Math.ceil(totalAttd / attdPerRow);
  const rows = Math.ceil(totalAttd / columns);
  return { rows, columns };
}

export function detectDevice() {
  if (window.matchMedia("(max-width: 767px)").matches) {
    return "Mobile";
  } else if (window.matchMedia("(min-width: 768px) and (max-width: 991px)").matches) {
    return "Tablet";
  } else {
    return "Desktop";
  }
}
